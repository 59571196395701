import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import STLViewer from "./components/StlViewer/StlViewer";
import Footer from "./components/footer/Footer";
import Error from "./components/error/Error";

function App() {
  return (
    <BrowserRouter>
      <div className="App" style={{ minHeight: "86vh" }}>
        <Routes>
          <Route exact path="/:id" element={<STLViewer />} />
          <Route path="/" element={<Navigate replace to="/not-found" />} />
          <Route path="/not-found" element={<Error />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
