import React from "react";
import Header from "../header/Header";

import "./error.css";

const Error = () => {
  return (
    <>
      {/* <Header /> */}

      <div className="error-container">
        <section id="not-found">
          <div className="circles">
            <p>
              404
              <br />
              <small>PAGE NOT FOUND</small>
            </p>
            <span className="circle big"></span>
            <span className="circle med"></span>
            <span className="circle small"></span>
          </div>
        </section>
      </div>
    </>
  );
};

export default Error;
