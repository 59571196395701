import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Styles from "./header.module.css";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";

import "./style.css";

const Header = () => {
  const [request, setRequest] = useState();
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const requestRes = await httpNonToken.get(
          `${endPoints.getRequestByCode}/${id}`
        );
        setRequest(requestRes.data);
      } catch ({ response }) {
        if (response.status === 404) {
          navigate("/not-found");
        } else {
          console.log(response.data.message);
        }
      }
    })();
  }, []);

  return (
    <div style={{ backgroundColor: "#1C1F3E", padding: "8px 12px", zIndex: 2 }}>
      <nav
        className={`navbar-expand-lg navbar-light bg_dark ${Styles.nav_section}`}
      >
        <div className={`d-flex justify-content-between  ${Styles.nav_box}`}>
          <div style={{ width: "150px", lineHeight: 4.5 }}>
            <img
              // src="/images/header/qubx_logo.svg"
              className="w-100"
              src="/images/header/logo.png"
              alt="qubx"
            />
          </div>
          <div className="text-white mt-4">
            {request && new Date(request.created_at).toLocaleString()}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
