import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../header/Header";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import Slide from "@mui/material/Slide";

import "./stl-viewer.css";
import clients from "../../data/clients.json";
import serviceblock from "../../data/service.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const STLViewer = () => {
  const { id } = useParams();
  const viewerIframeRef = useRef(null);
  const [request, setRequest] = useState();
  // const handle = useFullScreenHandle();
  const [open, setOpen] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const iframeRef = useRef(null);
  const [uid, setUid] = useState("");
  // let [isFullScreen, setFullScreen] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const requestRes = await httpNonToken.get(
          `${endPoints.getRequestByCode}/${id}`
        );
        setRequest(requestRes.data);
        setUid(requestRes.data.result_URL.split("/")[4]);
        if (requestRes.data.sketchfab) {
          let client = await new window.Sketchfab(viewerIframeRef.current);
          client.init(requestRes.data.result_URL.split("/")[4], {
            success: (api) => {
              api.start(function () {
                console.log("Viewer success");
                api.addEventListener("viewerready", function () {
                  api.setBackground(
                    {
                      uid: "c3d8e6730a03438cb584903b5800dd37",
                    },
                    function () {
                      console.log("Viewer success");
                    }
                  );
                });
              });
            },
            error: () => {
              console.log("Viewer error");
            },
            autostart: 1,
            ui_start: 1,
            ui_fadeout: 0,
            ui_infos: 0,
            ui_inspector: 0,
            ui_settings: 0,

            ui_vr: 0,
            ui_ar: 1,
            // ui_ar_qrcode: 1,
            ui_watermark_link: 0,
            ui_watermark: 0,
            ui_fullscreen: 0,
          });
        } else {
          viewerIframeRef.current.src = requestRes.data.result_URL;
        }
      } catch ({ response }) {
        console.log(response);
        if (response.status === 404) {
          navigate("/not-found");
        } else {
          console.log(response.data.message);
        }
      }
    })();
  }, [openModal]);

  const qubxNavigate = () => {};

  const ViewerIframe = (
    <iframe
      className="iframe-viewer"
      ref={viewerIframeRef}
      title={`Qubx viewer  request-${id}`}
      style={{ height: "100vh", width: "100%" }}
    />
  );

  const handleChangeExpand = () => {
    if (!openFlag) {
      handleClickOpenModal();
    } else {
      setOpenFlag(!openFlag);
      handleCloseModal();
    }
  };

  return (
    <div>
      <Header />
      <div className="py-3 px-5">
        <div className="d-md-flex justify-content-between">
          <div className="">
            <p className="h6">Patient: {request && request.patient.fullname}</p>
            <p className="h6">ID: {request && request.id}</p>
          </div>
          <div className="">
            <p className="h6">Branch: {request && request.branchName}</p>
            <p className="h6">Doctor: {request && request.doctor.fullname}</p>
          </div>
        </div>
        <div className="m-auto w-100 py-3 px-0">
          <div
            id="expand"
            className="qubx-viewer-container"
            style={{ position: "relative" }}
          >
            <i
              style={{
                position: "absolute",
                left: "15px",
                bottom: "10px",
                lineHeight: "50px",
                cursor: "pointer",
                zIndex: "1000",
                width: "50px",
                height: "50px",
                color: "text-white",
              }}
              className="fa-solid fa-up-right-and-down-left-from-center text-white text-center expand-icon"
              onClick={handleClickOpenModal}
            ></i>

            <div ref={iframeRef}> {ViewerIframe}</div>
          </div>
        </div>
        <div className="section section-padding sigma_service-sec style-16">
          <div className="">
            <div className="row">
              {/* Data */}
              {serviceblock.slice(0, 3).map((item, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                  <div className="sigma_service style-16">
                    <div className="sigma_service-thumb">
                      <img src={item.icon} alt="img" className="w-75" />
                    </div>
                    <div className="sigma_service-body">
                      <h5>{item.title}</h5>
                      <p>{item.shorttext}</p>
                      <a
                        href={item.link}
                        target="_blank"
                        className="btn-link primary-color text-decoration-none"
                      >
                        {item.type === "3d_DIGITAL_Files"
                          ? "Request a Free Model"
                          : "Contact us"}
                        <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="d-flex flex-md-row flex-column justify-content-between"> */}
        {/* <div className="margin-negative">
          <div className="row">
            <div className="col-lg-6">
              <div className="sigma_cta style-14">
                <div className="sigma_cta-content d-block d-sm-flex align-items-center">
                  <i className="fa-solid fa-message"></i>

                  <h4 className="mt-3 mt-sm-0">
                    Interested in Qubx 3D? Try Our Services Now!
                  </h4>
                </div>
                <p className="text-white">
                  TAKE THE CHANCE TO ORDER A 3D MODEL AND TRY IT FOR FREE. LEAVE
                  US A REQUEST AND WAIT FOR A CALL ASAP.
                </p>
                <form>
                  <div className="">
                    <a
                      target="_blank"
                      href="https://qubx3d.com/qubx/contact"
                      className="sigma_btn light"
                    >
                      Contact Us
                      <i className="fal fa-plus ml-3 d-none d-sm-inline-block" />
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 mt-md-2">
              <div className="sigma_clients-wrapper style-3">
                <div className="row">
                  {clients.slice(0, 4).map((item, i) => (
                    <div className="col-lg-6 col-md-6" key={i}>
                      <div className="sigma_clients style-3">
                        <img
                          src={process.env.PUBLIC_URL + "/" + item.image}
                          alt="clients"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {openModal ? (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.99)",
            zIndex: 1001,
          }}
        >
          <div id="expand" className="qubx-viewer-container">
            <i
              style={{
                position: "absolute",
                left: "15px",
                bottom: "10px",
                lineHeight: "50px",
                cursor: "pointer",
                zIndex: "1000",
                width: "50px",
                height: "50px",
              }}
              className="fa-solid fa-up-right-and-down-left-from-center text-white text-center expand-icon"
              onClick={handleCloseModal}
            ></i>

            <div> {ViewerIframe}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default STLViewer;
